import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-gallery';
 import tribalHaatImage from './tribal-haat.png'; 

import 'react-image-gallery/styles/css/image-gallery.css';
import './App.css';
import tribalHaatImage1 from './tribalhaat1.jpg';
import tribalHaatImage2 from './tribalhaat2.jpg';
import tribalHaatImage3 from './tribalhaat3.jpg';
import tribalHaatImage4 from './tribalhaat4.jpg';
import tribalHaatImage5 from './tribalhaat5.jpg';
import tribalHaatImage6 from './tribalhaat6.jpg';

function App() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Define your gallery images
    const galleryImages = [
      { original: tribalHaatImage1, thumbnail: tribalHaatImage1 },
      { original: tribalHaatImage2, thumbnail: tribalHaatImage2 },
      { original: tribalHaatImage3, thumbnail: tribalHaatImage3 },
      { original: tribalHaatImage4, thumbnail: tribalHaatImage4 },
      { original: tribalHaatImage5, thumbnail: tribalHaatImage5 },
      { original: tribalHaatImage6, thumbnail: tribalHaatImage6 },

      // Add more images as needed
    ];

    setImages(galleryImages);
  }, []);

  return (
          <div className="App">
            <header className="App-header">
              <img src={tribalHaatImage} alt="Tribal Haat Music" className="App-logo" />
              <h1>Welcome to Tribal Haat </h1>
              <p>Bringing you the vibrant beats of Nepal's diverse folk music !</p>
              
            </header>
            <div className='Body'>
              <body className='Body-header'>
      
                <h1>
                  Full site coming soon  . . . 
                </h1>

                <Gallery items={images}
                showNav = {false}
                autoPlay= {true}
                showThumbnails={false}
                showFullscreenButton={false}
                useBrowserFullscreen={false}
                showPlayButton={false}
                

                />

                <p>
                  <h1>
                    For more detail . .
                  </h1>
                  Contact Detail <br/><br/>
                  Phone: +977-9862658255<br/><br/>
                  Email: info@tribalhaatnepal.com, tribalhaatnepal@gmail.com <br/>
                  <br/>
                  <location>
                    Jhamsikehl, lalitpur
                    </location>                  
                </p>

      
              </body>
      
            </div>
          </div>
        
      
  );
}

export default App;

